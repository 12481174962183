import React, { useContext, useState } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { connect } from 'react-redux'
import Layout from '../../templates/Restorer'
import Heading from '../../modules/Heading'
import AddEdit from '../../modules/AddEdit'
import Select from 'react-select'
import VehicleUpload from '../../modules/VehicleUpload'
import { clearUserVehicle } from '../../system/redux/reducers/vehicle'
import verifyAuth from '../../system/firebase/requireAuth'
import { ThemeContext } from 'styled-components'

const customSelect = (theme) => ({
    singleValue: () => ({
        color: theme.colors.grey,
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
        ...provided,
        border: '1px solid ' + theme.colors.grey,
        backgroundColor: isSelected
            ? theme.colors.aquaLight
            : theme.colors.charcoal,
        borderColor:
            isFocused || isSelected
                ? theme.colors.aquaMedium
                : theme.colors.aquaDark,
        color: isSelected ? theme.colors.charcoal : theme.colors.grey,
        padding: theme.space.xs,
        cursor: 'pointer',
    }),
    control: () => ({
        cursor: 'pointer',
        color: theme.colors.grey,
        backgroundColor: theme.colors.charcoal,
        borderColor: theme.colors.aquaMedium,
        borderStyle: 'solid',
        borderWidth: '1px',
        display: 'flex',
        padding: '0',
        height: '35px',
    }),
    menuList: () => ({
        padding: '0px',
    }),
    dropdownIndicator: () => ({
        padding: '0px',
        marginLeft: '8px',
        marginRight: '8px',
        width: '20px',
    }),
})

function Add({ dispatch }) {
    const theme = useContext(ThemeContext)
    const [type, setType] = useState('client')
    const options = [
        { label: 'User', value: 'client' },
        { label: 'Vehicle', value: 'vehicle' },
    ]

    const handleSelectChange = (e) => {
        dispatch(clearUserVehicle())
        setType(e.value)
    }
    const output = type === 'vehicle' ? <VehicleUpload /> : null
    return (
        <Layout minHeight='100vh'>
            <Heading size='h1' text='Add New' />
            <Flex justifyContent='flex-end' alignItems='center' mx='md'>
                <Box width='300px' alignSelf='flex-end' mb='sm'>
                    <Select
                        styles={customSelect(theme)}
                        onChange={(e) => handleSelectChange(e, 'type')}
                        placeholder='Add'
                        options={options}
                        defaultValue={{ label: 'User', value: 'client' }}
                    />
                </Box>
            </Flex>
            <Flex alignItems='center' bg='charcoal' py='md' minHeight='63.5vh'>
                <Box width='100%' py='md' variant='container'>
                    {output}
                    <AddEdit type={type} />
                </Box>
            </Flex>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {}
}
export default verifyAuth(connect(mapStateToProps)(Add))
